@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

.map {
  background-color: grey;
  margin-bottom: 30px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.app-container {
  margin: 24px;
}

/* YourComponent.css */

/* Hide the default checkboxes */
input[type='checkbox'] {
  position: absolute;
  opacity: 0;
}

/* Style the custom checkboxes */
.checkbox-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.label-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #1985ac; /* Border color for unchecked checkboxes */
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.custom-checkbox:hover {
  background-color: #f0f0f0; /* Background color on hover */
}

/* Style the checked custom checkboxes */
.custom-checkbox.checked {
  background-color: #1985ac; /* Background color for checked checkboxes */
  border-color: #1985ac; /* Border color for checked checkboxes */
}

/* Styling the checkmark inside the checked checkboxes */
.custom-checkbox.checked::after {
  content: '\2713'; /* Unicode character for checkmark */
  color: #fff; /* Color of the checkmark */
  font-size: 14px;
}

.scorecard {
  margin-top: 48px;
  margin-bottom: 32px;
}
/* If you want to adjust the size of the checkmark, you can change the font-size */

button[type='submit'] {
  padding: 10px 20px;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color linear 0.3s;
  margin-left: 16px;
}

.reset-button {
  margin-left: 8px;
  background: none;
  color: #007bff;
  border: none;
  padding: 0;
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;
}

input[type='text'] {
  margin-bottom: 16px;
  padding: 10px;
  border-radius: 5px;
  background-color: white;
  font-size: 16px;
  color: #1e49d5;
  transition: all 0.2s ease-in-out;
}

input[type='text']:focus {
  outline: none;
}

label {
  font-size: 14px;
  color: #333;
}

h1 {
  margin-top: 48px;
  font-size: 32px;
}
h2 {
  margin-top: 48px;
}
h3 {
  margin-top: 28px;
  color: red;
}
h4 {
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
}

/* Nav.css */

.navbar {
  background-color: #fff; /* Set the background color of the navbar to white */
  margin-top: 28px;
}

.nav-links {
  list-style-type: none; /* Remove the bullet points from the list */
  display: flex;
  justify-content: left;
  margin: 0; /* Remove any margin from the list */
  padding: 0; /* Remove any padding from the list */
}

.nav-link {
  text-decoration: none; /* Remove the default underline on the links */
  color: #1985ac; /* Set the text color of the links to a light blue */
  margin-right: 16px;
  position: relative; /* Create a relative positioning context for the ::after pseudo-element */
}

.nav-link::after {
  content: '';
  display: block;
  position: absolute;
  bottom: -2px; /* Position the underline below the text */
  left: 0;
  width: 100%; /* Set the underline width to match the link's width */
  height: 2px; /* Set the height of the underline */
  background-color: #1985ac; /* Set the initial underline color */
  transform: scaleX(0); /* Scale the underline to 0 width initially */
  transform-origin: bottom right; /* Set the origin of the transformation */
  transition: transform 0.2s ease-out; /* Add animation to the underline */
}

.nav-link:hover::after {
  transform: scaleX(1); /* Scale the underline to full width on hover */
  transform-origin: bottom left; /* Set the origin of the transformation on hover */
}
iframe {
  margin: 16px;
}
